import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Tooltip
} from "@material-ui/core";
import { useFormik } from 'formik';
import { GetClientCaseId, GetUserId } from "../../../../Functions/General";
import Axios from "axios";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useLocation } from "react-router";
import InfoIcon from '@material-ui/icons/Info';
import { Form } from "react-bootstrap";
export default function NotifyAttorney(){
    const [open, setOpen] = useState(false);
    const [sent, setSent] = useState(false);
    const [message, setMessage] = useState("Sending");
    const [info, setInfo] = useState("");
    const [textNote, setTextNote] = useState("");
    const client_case_id = GetClientCaseId();
    const user_id = GetUserId();
    const location = useLocation();
    
    const handleModalState = () => {
        setOpen(!open);
    };
    
    const handleSubmit = async (values) => {
        await Axios.post('/api/v2/documents/notify', values)
        .then((res) => {
            setMessage('Lawyers Notified!');
            setSent(true);
            handleModalState();
            formik.resetForm();
        })
        .catch(err => setMessage('Server Error!'))
        .finally(() => {formik.setSubmitting(false)});
    };
    
    const formik = useFormik({
        initialValues:{
            message: '',
            description: '',
            client_case_id: client_case_id,
            user_id: user_id
        },
        onSubmit: handleSubmit
    });

    const handleInfoText = () => {
        let curr_loc = location.pathname;
        if(String(curr_loc).includes('documents')){
            setInfo('Each time you have uploaded documents, please click this button to notify your legal team that have you have done so.');
            setTextNote('Please use the text box below for anything you wish to tell your legal team.');
        }
        if(String(curr_loc).includes('notes')){
            setInfo('Each time you create a note you wish you lawyer to review, please click this button to notify your legal team that have you have done so.');
            setTextNote('Please use the text box below for anything you wish to tell your legal team.');
        }
        if(String(curr_loc).includes('basics') || String(curr_loc).includes('estate') || String(curr_loc).includes('budget') || String(curr_loc).includes('timeline') || String(curr_loc).includes('children')){
            setInfo('Please click this button to notify your legal team that you have filled out the page. You can use the text box to let your legal team know whether you have fully completed the page or whether there are some items that are not yet fully complete.');
            setTextNote('Please use the text box below for anything you wish to tell your legal team. If you have filled out the page but it is not yet fully complete, please use the text box to let your legal team know this.');
        }
    };

    const handleDescription = () => {
        let curr_loc = location.pathname;
        if(String(curr_loc).includes('estate')){
            formik.setFieldValue('description',"wants you to know that they have added information and/or uploaded files to their ESTATE PAGE. If the client opted to add anything additional to this message, their message will appear below.")
        }
        if (String(curr_loc).includes('budget')){
            console.log('budget');
            formik.setFieldValue('description',"wants you to know that they have added information to their BUDGET PAGE. If the client opted to add anything additional to this message, their message will appear below.")
        }
        if (String(curr_loc).includes('children')){
            console.log('children');
            formik.setFieldValue('description',"wants you to know that they have added information to their CHILDREN PAGE. If the client opted to add anything additional to this message, their message will appear below.")
        }
        if (String(curr_loc).includes('notes')){
            console.log('notes');
            formik.setFieldValue('description',"wants you to know that they have added information and/or uploaded files to their NOTES PAGE. If the client opted to add anything additional to this message, their message will appear below.")
        }
       if (String(curr_loc).includes('timeline')){
            console.log('timeline');
            formik.setFieldValue('description',"wants you to know that they have added information and/or uploaded files to their TIMELINE PAGE. If the client opted to add anything additional to this message, their message will appear below.")
        }
       if (String(curr_loc).includes('basics')){
            console.log('basics');
            formik.setFieldValue('description',"wants you to know that they have added information and/or uploaded files to their BASICS PAGE. If the client opted to add anything additional to this message, their message will appear below.")
        }
    }
    
    useEffect(() => {
        setTimeout(() => {
            setSent(false);
        }, 4000);
    },[sent]);
    
    useEffect(() => {
        handleInfoText();
    }, []);

    useEffect(() => {
        handleDescription();
    },[open]);
    return(
        <>
            <button className="btn btn-primary" onClick={() => handleModalState()} disabled={formik.isSubmitting}>
                {formik.isSubmitting ? <div className="spinner-border spinner-border-sm"></div> : "Notify Lawyer"}
            </button>
            <Tooltip title={info}>
                <InfoIcon style={{ color: 'gray'}}/>
            </Tooltip>
            <Snackbar
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}
                open={sent}
                autoHideDuration={6000}
                onClose={() => setSent(false)}
                message={message}
                action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={() => handleModalState()}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
                }
            />
            <Dialog maxWidth="md" fullWidth open={open}>
                <DialogTitle>Notify Lawyer</DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent dividers>
                        <div className="d-flex flex-column">
                            <Form.Label>{textNote}</Form.Label>
                            <TextField {...formik.getFieldProps("message")} multiline minRows={4} variant="outlined"/>
                        </div>
                    </DialogContent>
                    <DialogActions className="p-8 d-flex flex-row justify-content-start">
                        <button type="submit" className="btn btn-success" disabled={formik.isSubmitting}>
                        {formik.isSubmitting ? <div className="spinner-border spinner-border-sm"></div>: "Submit"}
                        </button>
                        <button type="button" className="btn btn-primary" disabled={formik.isSubmitting} onClick={() => handleModalState()}>Cancel</button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};